import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";

import "./new-styles.scss";
import underline from "../../../images/csm/new-underline.png";
import calendar from "../../../images/ksliveimages/calendar.svg";
import clock from "../../../images/ksliveimages/clock.svg";

// import liveImage from "../../../images/ksliveimages/live-image.svg";
import NewKsLiveForm from "../../Forms/KSLiveRegForm/new-form";
import { CircularProgress } from "@mui/material";
import { CONVEX_API_URL } from "../../../../config/config";
import moment from "moment";
import { navigate } from "gatsby";

const HeroSection = (props) => {
  const eventID = props?.params?.id ?? null;

  const location = useLocation();
  const [eventDetails, setEventDetails] = useState(null);
  const [eventDataloading, setEventDataloading] = useState(false);

  useEffect(() => {
    if (eventID) {
      getEventDetails();
      setEventDataloading(true);
    }
  }, []);

  const getEventDetails = async () => {
    try {
      const url = `${CONVEX_API_URL}/program/null/campaign/null/events/${eventID}`;

      const res = await fetch(url);
      const data = await res.json();

      console.log("datat", data);

      if (
        data?.end_date_time &&
        moment(data?.end_date_time).isBefore(moment())
      ) {
        navigate("/kraftshala-live/");
      }

      setEventDetails(data);
      setEventDataloading(false);
    } catch (error) {
      console.log(error);
      setEventDataloading(false);
    }
  };

  if (eventDataloading) {
    return (
      <div className="ks-live-loader-main">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      <div className="ks-live-breadcrums">
        <span className="home">Home &nbsp; {">"} &nbsp;</span>
        <span className="live-title">Live Session: {eventDetails?.title}</span>
      </div>
      <div className="ks-live-reg-container">
        <div className="ks-live-reg-image-container">
          <div className="ks-live-reg-image">
            <div className="banner-img-container">
              <img
                className="banner-img-desktop"
                src={eventDetails?.desk_popup_banner}
                alt="banner image desktop"
                loading="lazy"
              />
              <img
                className="banner-img-mobile"
                // src="https://s3.ap-south-1.amazonaws.com/convexstorage.kraftshala.com/production/a1e4e250-cdf4-4db5-9b01-a432359376ec/events/m-popup-banner-upload/14i86yix13lto8lc09.png"
                loading="lazy"
                src={eventDetails?.mob_popup_banner}
                alt="mobile banner"
              />
            </div>
            {/* <h2>{eventDetails?.speakers}</h2> */}
          </div>
          <div className="ks-live-reg-info">
            <div className="card-header">{eventDetails?.title}</div>
            <div className="underline-img">
              <img src={underline} loading="lazy" />
            </div>
            <div className="ks-live-reg-desc">
              {eventDetails?.description}
            </div>
            <div className="ks-live-reg-time-date">
              <div className="ks-live-date">
                <div className="ks-live-date-time-image">
                  <img src={calendar} />
                </div>
                <div className="ks-live-date-time-info">
                  <h5>Event Date</h5>
                  <h3>
                    {moment(eventDetails?.start_date_time).isValid()
                      ? moment(eventDetails?.start_date_time)
                        .format("LL")
                        .split(",")[0]
                      : "--"}
                  </h3>
                </div>
              </div>
              <div className="ks-live-date">
                <div className="ks-live-date-time-image">
                  <img src={clock} />
                </div>
                <div className="ks-live-date-time-info">
                  <h5>Time</h5>
                  <h3>
                    {moment(eventDetails?.start_date_time).isValid()
                      ? moment(eventDetails?.start_date_time).format("LT")
                      : "--"}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ks-live-reg-form-container">
          <NewKsLiveForm eventDetails={eventDetails} search={location.search} />
        </div>
      </div>
    </>
  );
};

export default HeroSection;
