import React from "react";
import live from "../../../images/ksliveimages/live.png";
import industryExpert from "../../../images/ksliveimages/industry-expert.png";
import free from "../../../images/ksliveimages/free.png";
import benefitsImgMob from "../../../images/ksliveimages/benefits-mobile.png";

import "../HeroSection/new-styles.scss";

const NewHighlightBar = () => {
  return (
    <div className="ks-live-benefits">
      <div className="benefits-header">Don't miss out, here's why :</div>
      <div className="benefits-images-container">
        <div className="benefits-image">
          <div>
            <img src={free} loading="lazy" className="free-img" />
          </div>
          <h3 className="benefit-text">Free Session</h3>
        </div>
        <div className="benefits-image">
          <div style={{paddingTop: '1.4em'}}>
            <img src={live}  loading="lazy" style={{height: '92px'}} className="live-img" />
          </div>
          <h3 className="benefit-text" >Live Streaming</h3>
        </div>
        <div className="benefits-image" style={{marginTop: '1.5em'}}>
          <div>
            <img src={industryExpert} loading="lazy" className="expert-img" />
          </div>
          <h3>Insights from industry experts</h3>
        </div>
      </div>
      <div className="benefits-image-mobile">
        <img src={benefitsImgMob} loading="lazy" className="live-img" />
      </div>
    </div>
  );
};

export default NewHighlightBar;
